import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
   // 聊天机器人
   {
    path: '/',
    name: 'html',
    component: () => import('@/components/Html-w.vue')
  },
  
   {
    path: '/muisr',
    name: 'muisr',
    component: () => import('@/components/muisr.vue')
  },
   {
    path: '/asdsadsddddj',
    name: 'asdj',
    component: () => import('@/components/asdj/index.vue')
  },
  
   {
    path: '/jihm',
    name: 'jihm',
    component: () => import('@/components/jihm/index.vue')
  },
   {
    path: '/sdask',
    name: 'sdask',
    component: () => import('@/components/sdask/index.vue')
  },
   {
    path: '/klasld',
    name: 'klasld',
    component: () => import('@/components/klasld/index.vue')
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;